import React, { useEffect } from 'react';
import './App.css';
import Hero from './components/Hero';

function App() {
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(searchParams.entries());
    
    if (Object.keys(params).length > 0) {
      const clickUrl = new URL('https://ads.octagon-lab.com/click');
      Object.keys(params).forEach(key => {
        clickUrl.searchParams.append(key, params[key]);
      });
      
      fetch(clickUrl.toString(), { method: 'GET' })
        .catch(error => console.error('Error sending click:', error));
    }
  }, []);

  return (
    <div className="App">
      <Hero />
    </div>
  );
}

export default App;
