import React, { memo } from 'react';
import { motion } from 'framer-motion';
import { containerVariants, fadeInLeftVariant } from '../constants/animations';
import {FaStar} from "react-icons/fa";

interface HeroProps {
  backgroundClassName?: string;
}

const Hero: React.FC<HeroProps> = ({ 
  backgroundClassName = "bg-gradient-to-br from-blue-50 to-purple-50 dark:from-gray-900 dark:to-gray-800"
}) => {
  return (
    <div className="min-h-screen flex flex-col relative overflow-hidden">
      <div className={`absolute inset-0 ${backgroundClassName}`} />
      
      <section className="flex-grow px-6 pt-8 pb-16 md:pt-8 md:pb-12 relative z-10">
        <motion.div 
          variants={containerVariants}
          initial="hidden"
          animate="visible"
          className="max-w-7xl mx-auto"
        >
          <div className="flex flex-col md:flex-row items-center justify-between gap-8">
            <motion.div 
              {...fadeInLeftVariant}
              className="max-w-xl w-full"
            >
              <img 
                src={`/logo192.png`}
                alt="2Phone Logo" 
                className={`h-[50px] w-auto mb-4`}
                draggable="false"
              />
              <div className="text-left">
                <div className="flex flex-wrap gap-2 md:gap-4 justify-start mb-4 md:mb-6">
                  <div className="inline-block px-4 py-2 bg-blue-50/80 backdrop-blur-sm dark:bg-gray-800/80 rounded-full shadow-lg hover:shadow-xl transition-all duration-300">
                    <span className="text-blue-600 dark:text-blue-400 font-medium text-sm flex items-center gap-2">
                      🌍 <span>Worldwide Coverage</span>
                    </span>
                  </div>
                  <div className="inline-block px-4 py-2 bg-green-50/80 backdrop-blur-sm dark:bg-gray-800/80 rounded-full shadow-lg hover:shadow-xl transition-all duration-300">
                    <span className="text-green-600 dark:text-green-400 font-medium text-sm flex items-center gap-2">
                      🔒 <span>Private Numbers</span>
                    </span>
                  </div>
                  <div className="inline-block px-4 py-2 bg-yellow-50/80 backdrop-blur-sm dark:bg-gray-800/80 rounded-full shadow-lg hover:shadow-xl transition-all duration-300">
                    <span className="text-yellow-600 dark:text-yellow-400 font-medium text-sm flex items-center gap-2">
                      💫 <span>Any Services</span>
                    </span>
                  </div>
                </div>
                <motion.h1 
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.8, delay: 0.3 }}
                  className="font-epilogue font-bold text-4xl md:text-5xl mb-4 bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-purple-600 dark:from-blue-400 dark:to-purple-400"
                >
                  Your Second Phone Number
                </motion.h1>
                <p className="font-epilogue text-lg md:text-xl text-gray-600 dark:text-gray-300 mb-6">
                  Make calls, send SMS, and stay private with virtual phone numbers from anywhere in the world
                </p>
              </div>

              <div className="flex justify-start mb-6">
                <a
                  href="https://apps.apple.com/app/id6478869509"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="transition-transform hover:scale-105 duration-200 select-none"
                >
                  <img 
                    src="/appstore.svg" 
                    alt="Download on the App Store" 
                    className="h-[70px]"
                    draggable="false"
                  />
                </a>
              </div>
              
              <div className="space-y-6">
                <motion.div 
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.3, duration: 0.6 }}
                  className="flex flex-col items-start gap-4"
                  >
                    <div className="flex items-center gap-4 bg-white dark:bg-gray-800 p-3 rounded-xl shadow-sm">
                      <StarRating />
                      <span className="text-gray-700 dark:text-gray-300 font-medium">
                        4.8 (30K+ reviews)
                      </span>
                    </div>
                  </motion.div>
                </div>
              </motion.div>

            <motion.div 
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.6, duration: 0.8 }}
              className="w-full max-w-sm md:max-w-md"
            >
              <div className="relative">
                <div className="relative w-full overflow-hidden">
                  <img 
                    src="/promo.png" 
                    alt="2Phone App" 
                    className="w-full h-auto"
                    draggable="false"
                  />
                </div>
              </div>
            </motion.div>
          </div>
        </motion.div>
      </section>
      
      <footer className="app-footer py-4 bg-white/80 dark:bg-gray-900/80 backdrop-blur-sm">
        <p className="text-center text-gray-600 dark:text-gray-400">
          &copy; 2Phone {new Date().getFullYear()}
        </p>
      </footer>
    </div>
  );
};

const StarRating = memo(() => (
  <div className="flex">
    {[...Array(5)].map((_, i) => (
      <FaStar key={i} className="text-yellow-400 w-5 h-5" />
    ))}
  </div>
));

export default memo(Hero);
